.container--grid-single {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin: -1em 0 0 -1em; */
  justify-content: center;
}

.container--1month {
  width: 100%;
}

.month--single {
  padding: 20px;
  min-height: 350px;
  position: relative;
  margin-top: 30px;
  box-shadow: 0px 2px 10px rgba(10, 23, 29, 0.15);
}
  
.list--single {
  list-style-type: none;
  padding: 0;
}

.list--single > li {
  padding: 4px;
}

.container--item {
  display: flex;
  flex-direction: row;
}

.item__options {
  padding: 0px 6px;
}

.edit-btn {
  margin-right: 10px;
  border: 0px;
  font-size: 15px;
  color: #011c33;
}

.edit-btn:hover {
  cursor: pointer;
}

.container--title {
  display: flex;
  justify-content: center;
  position: relative;
}

.arrow-back {
  position: absolute;
  left: 0;
  cursor: pointer;
  padding: 0px 15px 0px 0px;
  color: #011c33;
  transition: all .2s ease-in-out; 
}

.arrow-back:hover {
  transform: scale(1.1);
}

.complete {
  font-style: italic;
  text-decoration: line-through;
}

.incomplete {
  font-style: normal;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .month__year {
    flex-basis: 50%;
    position: relative;
  }

  .container--title {
    position: static;
  }

  .month--single {
    padding: 30px;
    margin-top: 35px;
  }
}