.app__nav {
  background-color: #183642;
  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  align-items: center;
  flex: 0 0 auto;
  min-height: auto;
  max-height: 45px;
  box-shadow: 0px 3px 8px rgba(10, 23, 29, 0.25);
}
  
.app-title {
  text-align: left;
  font-size: .95em;
  margin: 0 0 0 12px;
  color: #EAEAEA;
}

.app-icon {
  display: inline-block;
  font-size: 1em;
  margin-right: 12px;
  color: #f3de8a;
}
  
.nav-list {
  list-style-type: none;
  padding: 6px 12px;
}
  
.nav-list > li {
  display: inline-block;
  font-size: 14px;
  /* margin: 0 0 0 12px; */
}

.nav-list > li > a {
  font-weight: bold;
  text-decoration: none;
  color: #eaeaea;
}

.nav-list > li.greeting {
  color: #f3de8a;
  font-size: 1.125em;
}

.nav-list__btn-logout {
  font-weight: bold;
  color: #eaeaea;
  font-size: inherit;
  border: 0px;
  background-color: transparent;
  margin-right: 12px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .app-title {
    font-size: 1.35em;
  }
  .nav-list {
    list-style-type: none;
    margin: 0 0 0 12px; 
  }
  .nav-list > li {
    font-size: 1em;
  }
  .app__nav {
    min-height: 50px;
    max-height: auto;
  }
}