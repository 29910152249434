html, body, #root, .App {
  height: 100%;
}
  
body {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1,h2,h3 {
  font-family: "Open Sans";
}

li, a, p {
  font-family: "Karla";
}

.red {
  color: #962f2b;
}

/* #root {
  background-image: linear-gradient(to bottom, #eaeaea, #dddfe1, #cfd4d9, #bfcacf, #afc0c3);
  background-repeat: no-repeat;
} */
/* .app-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
} */

.wrapper {
  min-height: 100%;
  position: relative;
}
  
ul {
  line-height: 1.5;
}

main {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  flex: 1 0 auto;
  padding: 0px 1em;
  box-sizing: border-box;
}

footer {
  height: 35px;
  background-color: gray;
  margin-top: 50px;
  width: 100%;
  flex-shrink: 0;
  background-color: #183642;
}

/**** MEDIA QUERIES ****/

@media screen and (min-width: 768px) {
  main {
    max-width: 768px;
    width: 100%;
    flex-shrink: 0
  }
}

@media screen and (min-width: 1200px) {
  main {
    max-width: 1200px;
    width: 100%;
    flex-shrink: 0
  }
}