.container--landing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: -1em 0 0 -1em; */
    justify-content: center;
}

.container--form {
    font-size: 18px;
}
  
.registrationForm {
    border: 2px solid gray;
    border-radius: 4px;
    padding: 30px 26px;
    max-width: 345px;
}
  
label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    padding: 2px;
}
  
.registrationForm__input {
    margin-bottom: 18px;
    padding: 3px 7px;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
}
  
.registrationForm__submit {
    margin-top: 15px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    background: #c0e0de;
    font-weight: bold;
    color: #252422;
    transition: all .2s ease-in-out;
    box-shadow: 0px 2px 10px rgba(10, 23, 29, 0.15);
}
  
.registrationForm__submit:hover {
    transform: scale(1.01);
    box-shadow: 0px 2px 12px rgba(10, 23, 29, 0.20);
}