.container--grid-single {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
  
.container--1month {
    width: 100%;
}

.form--edit {
    position: relative;
    padding: 12px 0px;
}

.form__label--edit {
    display: block;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
    padding-left: 6px;
}

.form__input--edit {
    width: 100%;
    margin-bottom: 15px;
    font-size: 1em;
    height: 30px;
    box-sizing: border-box;
    padding: 6px;
    background-color: #fff;
}

.form__submit--edit {
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: bold;
    display: block;
    background-color: #f5f8fb;
    width: 25%;
    margin: 25px 0px 15px 0px;
    padding: 10px 0px;
    color: #3e5c76;
    border: 2px solid #3e5c76;
    cursor: pointer;
}

.button--delete {
    width: 50%;
    margin: 0 auto;
    display: block;
    font-weight: bold;
    font-size: 1em;
    margin-top: 35px;
    padding: 10px 0px;
    background-color: #fff2f2;
    color: #96332f;
    border: 2px solid  #96332f;
    cursor: pointer;
}