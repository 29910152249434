.container--grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -1em 0 0 -1em;
    justify-content: center;
}

.container--form {
    font-size: 18px;
}
  
.entry-form {
    border: 2px solid gray;
    border-radius: 4px;
    padding: 30px 26px;
    min-width: 200px;
    max-width: 300px;
}
  
label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    padding: 2px;
}
  
.entry-form__input {
    margin-bottom: 18px;
    padding: 3px 7px;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
}
  
.entry-form__submit {
    margin-top: 15px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    background: #ffc354;
    font-weight: bold;
    color: #011c33;
    transition: all .2s ease-in-out;
    box-shadow: 0px 2px 10px rgba(10, 23, 29, 0.15);
}
  
.entry-form__submit:hover {
    transform: scale(1.025);
    box-shadow: 0px 2px 12px rgba(10, 23, 29, 0.20);
}