header {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

h2 {
    margin: 45px auto;
    text-align: center;
    margin-left: 1em;
    margin-right: 1em;
    color: #183642;
}

.btn-arrow {
    padding: 0px 10px;
    background-color: transparent;
    border: 0px solid transparent;
    font-size: 1em;
    transition: .15s;
    color: #011c33;
    cursor: pointer;
}

.btn-arrow:hover {
    transition: all .2s ease-in-out; 
    transform: scale(1.1);
}