.container--grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -1em 0 0 -1em;
  justify-content: center;
}
  
.container--month {
  display: block;
  align-self: center;
  padding: 1em 0 0 1em;
  flex-basis: 100%;
  text-decoration: none;
}

.month {
  border: 2px solid rgb(212, 212, 212);
  padding: 1em;
  min-height: 250px;
  background-color: #fff;
}

.month:hover {
  border: 2px solid rgb(117, 117, 117);
}

.month--grid {
  max-width: 328.797px;
  box-shadow: 0px 2px 4px rgba(10, 23, 29, 0.10);
  transition: .15s;
}

.month__title {
  margin: 2% auto;
  font-size: 1.125em;
 }

.month__itemList {
 word-break: break-word;

}
.month__itemList > li{
   color: #191308;
 }
 .month__itemList > li:visited {
  color: #191308;
}
.month__itemList > li:hover {
  color: #191308;
}
  
  /**** MEDIA QUERIES ****/

@media screen and (min-width: 768px) {
  .intro {
    max-width: 700px;
  }
  .container--month {
    flex-basis: 40%;
  }
  .container--1month {
    flex-basis: 50%;
  }
  .container--grid {
    margin: -1em 0 0 -1em;
  }
}
  
@media screen and (min-width: 1200px) {
  .container--month {
    flex-basis: 30%;
  }
}