.container--intro {
  line-height: 1.5;
  font-size: 18px;
  flex-basis: 100%;
}
  
.intro {
  margin: 0 auto;
  padding: 1em;
  font-family: "Karla";
}

.intro__heading {
  font-size: 22px;
  text-align: left;
  margin: 40px 0px 25px 0px;
}

.intro > ul {
  padding-left: 20px;
}

.container--btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 75px 0 75px 0;
}
  
.btn {
  display: inline-block;
  border-radius: 4px;
  background-color: lightgray;
  padding: 12px 24px;
  color:black;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  flex-basis: 1;
  transition: all .2s ease-in-out;
  box-shadow: 0px 2px 10px rgba(10, 23, 29, 0.15);
}

.btn:hover {
  transform: scale(1.025);
  box-shadow: 0px 2px 12px rgba(10, 23, 29, 0.20);
}

.btn--login {
  background: #f3de8a;
  color: #252422;
}

.btn--register {
  color: #252422;
  background: #c0e0de;
}

.container--landing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
  
  /* .container--month {
    display: block;
    align-self: center;
    padding: 1em 0 0 1em;
    flex-basis: 100%;
    text-decoration: none;
  }

  .month {
    border: 2px solid rgb(212, 212, 212);
    padding: 1em;
    min-height: 250px;
  } */ 

/**** MEDIA QUERIES ****/

@media screen and (min-width: 768px) {
  .intro {
    max-width: 700px;
  }
  /* .container--month {
    flex-basis: 40%;
  }
  .container--1month {
    flex-basis: 50%;
  } */
}

/* @media screen and (min-width: 1200px) {
  .container--month {
    flex-basis: 30%;
  }
} */